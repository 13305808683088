import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ComboTotal from "./ComboTotal";
import ComboTotalEspecial from "./ComboTotalDiogo";
import ComboTotalHumberto from "./ComboTotalHumberto";
import ComboTotalRosani from "./ComboTotalRosani";
import ComboTotalErick from "./ComboTotalErick";
import CincoSegredos from "./CincoSegredos";
import CaroKann from "./CaroKann";
import ZeroProfessor from "./ZeroProfessor";
import EstrategiaModerna from "./EstrategiaModerna";
import CursoEstrategiaModerna from "./EstrategiaModernaDiogo";
import PetrofNepo from "./PetrofNepo";
import Todos from "./Todos";
import Trilogia from "./Trilogia";
import { inject } from '@vercel/analytics'; 
inject();

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/todos" element={<Todos />} index />
        <Route path="/combo-total" element={<ComboTotal />} />
        <Route path="/combo-total-especial" element={<ComboTotalEspecial />} />
        <Route path="/combo-total-hf" element={<ComboTotalHumberto />} />
        <Route path="/combo-total-es" element={<ComboTotalErick />} />
        <Route path="/combo-total-ro" element={<ComboTotalRosani />} />
        <Route path="/5-segredos" element={<CincoSegredos />} />
        <Route path="/como-enfrentar-caro-kann" element={<CaroKann />} />
        <Route path="/do-zero-a-professor-de-xadrez" element={<ZeroProfessor />} />
        <Route path="/estrategiamoderna" element={<EstrategiaModerna />} />
        <Route path="/curso-estrategiamoderna" element={<CursoEstrategiaModerna />} />
        <Route path="/petrof-do-nepo" element={<PetrofNepo />} />
        <Route path="/trilogia" element={<Trilogia />} />
      </Routes>
    </Router>
  );
}

export default App;
